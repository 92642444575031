import { FEATURE } from 'api/src/common/enums'

import { useAuth } from 'web/src/Providers'

const useFeature = (name: FEATURE): boolean => {
  const { currentUser } = useAuth()
  return currentUser?.featuresForUser?.includes(name) ?? false
}

export default useFeature
